import React from "react";
import * as PR from "../../prime-modules/index";
import { eSIMLogo } from "../../assets/images/index";
import * as Yup from 'yup';
import "./Login.scss";
import { useFormik } from "formik";
import { login } from "../../services/api";
import { useDispatch } from 'react-redux';
import { affiliateAuthActions } from "../../store/affiliateAuth";
import { Link } from "react-router-dom";

const Login = () => {
    const dispatch = useDispatch()
    const toast = React.useRef(null)

    const formInitialValues = {
        email: "",
        password: "",
    };

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().trim()
                .email('Invalid email')
                .required('Email is required'),
            password: Yup.string().trim().required('Password is required')
        });
    }

    const handleSubmit = values => {
        const loginRequest = {
            email: (values.email).trim(),
            password: values.password
        }
        const getLoginResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(affiliateAuthActions.onLogin(response.data));
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        login(loginRequest, dispatch, getLoginResponse);
    }


    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <>
            <section className="admin-login-section">
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <div className="login-wrapper">
                            <div className="login-header flex align-items-center justify-content-between">
                                <PR.Image src={eSIMLogo} alt="eSIMCrew Logo" className="logo-img" />
                                <h1>Affiliates Login</h1>
                            </div>
                            <form autoComplete="off" onSubmit={formik.handleSubmit} className="affiliate-forms">
                                <div className='grid'>
                                    <div className="col-12 md:col-12">
                                        <span className="p-input-icon-left w-12">
                                            <i className="pi pi-envelope" />
                                            <PR.InputText id="email" name="email" placeholder="Email" className="w-12"
                                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off"
                                            />
                                        </span>
                                        {formik.errors.email && formik.touched.email
                                            ? <div className="error-message">{formik.errors.email}</div>
                                            : ''
                                        }
                                    </div>

                                    <div className="col-12 md:col-12">
                                        <span className="p-input-icon-left password-left w-12">
                                            <PR.Password feedback={false} placeholder="Password" id="password" name="password" className="w-12" toggleMask
                                                value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off"
                                            />
                                        </span>
                                        {formik.errors.password && formik.touched.password
                                            ? <div className="error-message">{formik.errors.password}</div>
                                            : ''
                                        }
                                    </div>
                                    <p className="forgot-password">
                                        <Link to="/forgot-password">Forgot password?</Link>
                                    </p>
                                    <div className="col-12 mt-3 navigate">
                                        <PR.Button label="Login" type='submit' className="login-btn w-12" />
                                    </div>
                                    <div className="col-12 text-center">
                                       <p className="login-p">Don't have an account? <Link to="/signup">Sign Up</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
            <PR.Toast ref={toast} position="top-right" />
        </>
    );
};

export default Login;